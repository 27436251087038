// Hooks
import { useApplicationResumeModal } from "./hooks/useApplicationResumeModal";
import { useEffect, useRef, useState } from "react";
import { format } from "date-fns";
import handlePermissionCheck from "../../utilities/handlePermissionCheck";
import { AnimatePresence } from "framer-motion";

// Components
import { Link } from "react-router-dom";
import ApplicationPhoto from "./ApplicationPhoto";
import ApplicationRating from "./ApplicationRating";
import ApplicationAppointmentsAndComments from "./ApplicationAppointmentsAndComments";
import ApplicationDetails from "./ApplicationDetails";
import ApplicationContact from "./ApplicationContact";
import ApplicationCardHeader from "./ApplicationCardHeader";
import ApplicationSourceAdmin from "./ApplicationSourceAdmin";
import ApplicationSourceUser from "./ApplicationSourceUser";
import PermissionCheckComponentWrapper from "../Wrappers/PermissionCheckComponentWrapper";

// Components - Buckets
import ApplicationArchive from "./Buckets/ApplicationArchive";
import ApplicationFavorite from "./Buckets/ApplicationFavorite";

// Components - Socials
import ApplicationSocials from "./Socials/ApplicationSocials";
import ApplicationSendText from "./Socials/ApplicationSendText";
import ApplicationLinkedIn from "./Socials/ApplicationLinkedIn";
import ApplicationResume from "./Socials/ApplicationResume";
import ApplicationSendEmail from "./Socials/ApplicationSendEmail";
import ApplicationScheduleMeeting from "./Socials/ApplicationScheduleMeeting";

// Components - Modals
import ApplicationModalResumeRequest from "./modals/ApplicationModalResumeRequest";
import Modal from "../Modal/Modal";
import ScheduleMeetingMenu from "./ScheduleMeeting/ScheduleMeetingMenu";

// Interfaces
import { ApplicationCardProps } from "./interfaces";
import { useTour } from "../../providers/tour-context";

const ApplicationCardGrid = ({
  details,
  selectedApplications,
  isFocused = false,
  activeBucket,
  handleApplicationsSelection,
  handleApplicationResumePreview,
}: ApplicationCardProps) => {
  /*===============================
    RESUME MODALS
  ================================*/
  const [resumeModalType, handleResumeModal] = useApplicationResumeModal();

  useEffect(() => {
    if (resumeModalType !== "preview") return;
    handleApplicationResumePreview(details);
  }, [resumeModalType]);

  /*===============================
    SCHEDULE MEETING
  ================================*/
  const [isScheduleMeetingModalOpen, setIsScheduleMeetingModalOpen] = useState<boolean>(false);

  // If there's a "Video Meeting" scheduled for a future date,
  // redirect the user to the video conference page. Otherwise open the modal.
  const handleScheduleMeeting = () => {
    if (details.schedule_meeting) {
      window.open(`/vidconf/join/${details.schedule_meeting}`);
    } else {
      setIsScheduleMeetingModalOpen(true);
    }
  };

  const hasBucketsViewPermission = handlePermissionCheck(["buckets_view"]);

  /*===============================
    FOCUS ON THE APPLICATION
  ================================*/
  const applicationCardRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!isFocused) return;

    if (applicationCardRef.current) {
      applicationCardRef.current.scrollIntoView({
        block: "center",
        inline: "center",
        behavior: "auto",
      });
    }
  }, [isFocused]);

  const { tourCurrentStepName, isTourRunning, activeTour } = useTour();
  useEffect(() => {
    // Exit if theres no ongoing tour
    if (!isTourRunning || !activeTour || activeTour.tourId !== "applicants-ai-summary") return;

    // Scroll the window to the top of the section that is presented in the current step
    if (!applicationCardRef.current) return;
    const targetedElementStartingPosition = applicationCardRef.current.offsetTop;
    window.scrollTo({ top: targetedElementStartingPosition });
  }, [tourCurrentStepName, isTourRunning, applicationCardRef]);

  return (
    <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb--20">
      <div
        ref={applicationCardRef}
        className={`applications applications--grid ${
          selectedApplications.includes(details.id) ? "applications--selected" : ""
        } ${details.is_hidden ? "applications--hidden" : ""} ${
          isFocused ? "applications--is-focused" : ""
        } ${
          details.has_ai_sbca_summary && details.has_ai_resume_summary
            ? "applications--complete-ai-profile"
            : ""
        }`}
      >
        {details.is_hidden ? (
          <h6 className="applications__hidden btn btn--danger btn--pill btn--sm mx--auto mb--20">
            Hidden Application
          </h6>
        ) : null}
        {/* HEADER */}
        <ApplicationCardHeader
          id={details.id}
          name={details.name}
          email={details.email}
          registration_date={details.registration_date}
          hired_date={details.hired_date}
          activeBucket={activeBucket}
          applicantBucket={details.buckets[0]}
          sbca={details.sbca}
          sbca_report_identifier={details.sbca_report_identifier}
          sbca_request_date={details.sbca_request_date}
          has_ai_sbca_summary={details.has_ai_sbca_summary}
          isSelected={selectedApplications.includes(details.id)}
          hasDateSection
          handleApplicationSelection={() => handleApplicationsSelection(details.id)}
        />

        {/* USER PHOTO / INITIALS / RATING / APPOINTMENTS */}
        <div className="d-flex justify-content-between align-items-center my--10">
          <ApplicationRating comments_avg_rating={details.comments_avg_rating} />

          <Link to={`/applications/${details.id}/`}>
            <ApplicationPhoto name={details.name} photo={details.photo} sbca={details.sbca} />
          </Link>

          <ApplicationAppointmentsAndComments
            applicationID={details.id}
            appointments_count={details.appointments_count}
            comments_count={details.comments_count}
          />
        </div>
        {/* USER NAME / ROLE */}
        <ApplicationDetails id={details.id} name={details.name} position={details.position} />
        {/* USER ADDRESS / CONTACT PHONE */}
        <ApplicationContact location={details.location} phone={details.phone} />
        {/* USER SOCIALS ICONS */}
        <ApplicationSocials>
          <ApplicationSendText
            application_id={details.id}
            applicant_details={{
              id: details.applicant_id,
              text_message_consent: details.text_message_consent,
              name: details.name,
              photo: details.photo,
            }}
          />

          <ApplicationResume
            resume={details.resume}
            tooltipText={
              details.resume
                ? "Preview Resume"
                : details.resume_request_date
                ? `Resume Requested On ${format(
                    new Date(details.resume_request_date),
                    "MM/dd/yyyy",
                  )}. Click to Request Again`
                : "Request Resume"
            }
            handleResume={resume => {
              handleResumeModal(resume ? "preview" : "request");
              if (resumeModalType === "preview") handleApplicationResumePreview(details);
            }}
          >
            <AnimatePresence>
              {resumeModalType === "request" && (
                <ApplicationModalResumeRequest
                  id={details.id}
                  name={details.name}
                  email={details.email}
                  handleCloseModal={() => handleResumeModal(null)}
                />
              )}
            </AnimatePresence>
          </ApplicationResume>

          <ApplicationLinkedIn linkedin={details.linkedin} />

          <ApplicationSendEmail
            details={{
              applicantName: details.name,
              applicantEmail: details.email,
              applicationDate: details.registration_date,
              applicationPosition: details.position,
              applicantLocation: details.location,
            }}
          />

          <ApplicationScheduleMeeting
            meeting_type="video"
            tooltipText={details.schedule_meeting ? "Start Video Interview" : "Video Interview"}
            handleScheduleMeeting={handleScheduleMeeting}
          />
        </ApplicationSocials>
        {/* FOOTER */}
        <div
          className={`d-flex justify-content-${
            hasBucketsViewPermission ? "between" : "center"
          } align-items-end mb--20`}
        >
          <PermissionCheckComponentWrapper permissions={["buckets_view"]}>
            <ApplicationArchive
              id={details.id}
              currentBuckets={details.buckets}
              name={details.name}
            />
          </PermissionCheckComponentWrapper>

          <Link to={`/applications/${details.id}/`} className="applications__details-link">
            View Details
          </Link>

          <PermissionCheckComponentWrapper permissions={["buckets_view"]}>
            <ApplicationFavorite
              id={details.id}
              currentBuckets={details.buckets}
              name={details.name}
            />
          </PermissionCheckComponentWrapper>
        </div>
        {/* APPLICATION SOURCE */}
        <div className="d-flex justify-content-between">
          <ApplicationSourceAdmin source={details.source} />
          <ApplicationSourceUser source={details.source} />
        </div>
      </div>

      <AnimatePresence>
        {isScheduleMeetingModalOpen && (
          <Modal
            title=""
            text=""
            handleCloseModal={() => setIsScheduleMeetingModalOpen(false)}
            modifierClass="modal--fixated applications-schedule-meeting-modal"
          >
            <ScheduleMeetingMenu
              applicationID={details.id}
              meeting_link={details.meeting_link ?? details.schedule_meeting ?? "N/A"}
              handleCloseMenu={() => setIsScheduleMeetingModalOpen(false)}
            />
          </Modal>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ApplicationCardGrid;

// Utilities & Hooks
import { format, parseJSON } from "date-fns";
import { useState } from "react";
import { handleConvertSBCARatingToClassname } from "./utils/handleConvertSBCARatingToClassname";
import { useAuth } from "../../providers/auth-context";

// Interfaces
import { ApplicationSBCAProps } from "./interfaces";

// Components
import ApplicationModalSBCA from "./modals/ApplicationModalSBCA";
import { AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import Tooltip from "../Tooltip/Tooltip";

// Assets
import AiIcon from "../../assets/images/icons/assessment-ai.svg?react";

const ApplicationSBCA = ({
  id,
  name,
  email,
  sbca,
  sbca_report_identifier,
  sbca_request_date,
  has_ai_sbca_summary,
  modifierClass = "",
}: ApplicationSBCAProps) => {
  /*=======================
    CONTROL SBCA RENDER
  ========================*/
  const { user } = useAuth();

  // If the SBCA is disabled on company level, do not render anything in the UI
  if (user.active_company.disable_sbca) return null;

  /*=======================
    HANDLE "SBCA" MODAL
  ========================*/
  const [isSbcaModalOpen, setIsSbcaModalOpen] = useState<boolean>(false);

  return (
    <>
      <div className={`applications__sbca ${modifierClass}`}>
        {sbca != null ? (
          <>
            <Link
              to={`/assessment/results/${sbca_report_identifier}/`}
              className="applications__sbca__rating"
              target="_blank"
            >
              <p
                className={`applications__sbca__text applications__sbca__text--rating applications__sbca__text--rating--${handleConvertSBCARatingToClassname(
                  sbca,
                )}`}
              >
                {sbca.toFixed(2)}
              </p>{" "}
            </Link>

            {has_ai_sbca_summary ? 
              <Tooltip size="md" text="View AI Insights">
                <Link
                  to={`/assessment/results/${sbca_report_identifier}/?scrollTo=AIInsights`}
                  className="applications__sbca__subtext applications__sbca__subtext--ai-link"
                  target="_blank"
                >
                  <AiIcon />
                  <span className="mr--5">SBCA</span>
                  <span className="fw--semibold">AI</span>
                </Link>
              </Tooltip>
            : null}
          </>
        ) : sbca_request_date ? (
          <div className="applications__sbca__requested" onClick={() => setIsSbcaModalOpen(true)}>
            <p className="applications__sbca__text">Requested</p>
            <span className="applications__sbca__subtext">
              {format(parseJSON(sbca_request_date), "MM/dd/yyyy")}
            </span>
          </div>
        ) : (
          <div className="applications__sbca__request" onClick={() => setIsSbcaModalOpen(true)}>
            <p className="applications__sbca__text">Request</p>
            <span className="applications__sbca__subtext">SBCA</span>
          </div>
        )}
      </div>

      <AnimatePresence>
        {isSbcaModalOpen && (
          <ApplicationModalSBCA
            applicationID={id}
            applicantName={name}
            applicantEmail={email}
            handleCloseModal={() => setIsSbcaModalOpen(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default ApplicationSBCA;

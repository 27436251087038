import { ApplicationRouteProps } from "./interfaces";

// Authentication Routes
import { ROUTES_AUTHENTICATION } from "./authentication";

// Protected Routes
import { ROUTES_ACCOUNT } from "./account";
import { ROUTES_APPLICATIONS } from "./applications";
import { ROUTES_APPOINTMENTS } from "./appointments";
import { ROUTES_ARTICLES } from "./articles";
import { ROUTES_ASSESSMENT } from "./assessment";
import { ROUTES_CAREER } from "./career";
import { ROUTES_CHAT } from "./chat";
import { ROUTES_CLIENTS } from "./clients";
import { ROUTES_JOB_ADS } from "./job-ads";
import { ROUTES_LOGS } from "./logs";
import { ROUTES_PRIVILEGES } from "./privileges";
import { ROUTES_REPORTS } from "./reports";
import { ROUTES_JOB_POSITIONS } from "./job-positions";
import { ROUTES_ERROR_PAGES } from "./error-pages";

// Public Routes
import { ROUTES_ONLINE_APPLICATION } from "./online-application";
import { ROUTES_VIDEO_CONFERENCING } from "./video-conferencing";
import { ROUTES_INVITATIONS } from "./invitations";
import { ROUTES_MAINTENANCE } from "./maintenance";
import { ROUTES_TOURS } from "./tours";

const APPLICATION_PROTECTED_ROUTES: ApplicationRouteProps[] = [
  ...ROUTES_APPLICATIONS,
  ...ROUTES_APPOINTMENTS,
  ...ROUTES_ACCOUNT,
  ...ROUTES_ARTICLES,
  ...ROUTES_LOGS,
  ...ROUTES_CAREER,
  ...ROUTES_CHAT,
  ...ROUTES_ASSESSMENT,
  ...ROUTES_CLIENTS,
  ...ROUTES_JOB_ADS,
  ...ROUTES_PRIVILEGES,
  ...ROUTES_REPORTS,
  ...ROUTES_JOB_POSITIONS,
  ...ROUTES_TOURS,
];
const APPLICATION_AUTH_ROUTES: ApplicationRouteProps[] = [...ROUTES_AUTHENTICATION];
const APPLICATION_PUBLIC_ROUTES: ApplicationRouteProps[] = [
  ...ROUTES_ONLINE_APPLICATION,
  ...ROUTES_VIDEO_CONFERENCING,
  ...ROUTES_INVITATIONS,
  ...ROUTES_MAINTENANCE,
];
const APPLICATION_ERROR_PAGES: ApplicationRouteProps[] = ROUTES_ERROR_PAGES;

export const ROUTES: ApplicationRouteProps[] = [
  ...APPLICATION_PROTECTED_ROUTES,
  ...APPLICATION_AUTH_ROUTES,
  ...APPLICATION_PUBLIC_ROUTES,
  ...APPLICATION_ERROR_PAGES,
];

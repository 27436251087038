import { useExtractSearchParameters } from "../../hooks/useExtractSearchParameters";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

// Assets
import { FaAngleRight as PaginationRightIcon } from "react-icons/fa";
import { FaAngleLeft as PaginationLeftIcon } from "react-icons/fa";

// Interfaces
import { PaginationProps } from "./interfaces";

const Pagination = ({
  pageCount,
  currentPage = 0,
  containerClassName = "pagination pagination--hide-prevnext",
  shouldHandlePageRouteParameter = true,
  handlePageChange,
}: PaginationProps) => {
  const [currentPageState, setCurrentPageState] = useState<number>(currentPage);
  const [searchParametersObject, setSearchParametersObject] = useExtractSearchParameters();

  // Reflect any external updates to the current page state
  useEffect(() => {
    setCurrentPageState(currentPage);
  }, [currentPage]);

  // Handle page selection and search parameters updates
  const handlePagination = (selected: { selected: number }) => {
    // Trigger the received callback prop for handling page changes
    handlePageChange(selected);

    // Exit function if component shouldn't handle the search parameters
    if (!shouldHandlePageRouteParameter) return;

    // Update the state of the search parameters object to include the page
    // We intentionally increase + 1 to the selected page, so we can represent it
    // normally in the URL, because the pagination uses 0-based index.
    setSearchParametersObject({
      ...searchParametersObject,
      page: selected.selected + 1,
    });
  };

  // Pre-select the received page based on received search parameter when component mounts
  useEffect(() => {
    if (searchParametersObject.page && shouldHandlePageRouteParameter) {
      const pageNumber = parseInt(searchParametersObject.page);

      // If invalid page number is received (e.g. NaN), exit function
      if (!pageNumber || pageNumber < 0) return;

      // Update the local state for the selected page and trigger received callback
      // We're decreasing the page number here (opposite from when we set the search parameter trough selection)
      // so it can correctly reflect the page state, because the package reads `page=0` as the `page=1`
      if (pageNumber > pageCount) {
        // If the received page number is higher than the total pages count, default to last page
        setCurrentPageState(pageCount - 1);
        handlePageChange({ selected: pageCount - 1 });
      } else {
        setCurrentPageState(pageNumber - 1);
        handlePageChange({ selected: pageNumber - 1 });
      }
    }
  }, [searchParametersObject]);

  return (
    <ReactPaginate
      previousLabel={currentPageState !== 0 ? <PaginationLeftIcon /> : null}
      nextLabel={currentPageState + 1 !== pageCount ? <PaginationRightIcon /> : null}
      breakLabel={"..."}
      breakClassName={"pagination--break"}
      pageCount={pageCount}
      marginPagesDisplayed={1}
      pageRangeDisplayed={3}
      onPageChange={handlePagination}
      containerClassName={containerClassName}
      activeClassName={"active"}
      forcePage={currentPageState}
      disableInitialCallback={true}
    />
  );
};

export default Pagination;
